.container {
  width: 1080px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 80px;
  margin-bottom: 40px;
}

.title {
  margin-bottom: 40px;
}

.cardContainer {
  flex: 1;
}

.cardContent {
  padding: 0px 16px 16px 16px;
  flex: 1;
}

.articleSummary {
  height: 96px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  line-clamp: 4;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
}

.articleTags {
  margin-bottom: -6px;
}

.cardFooter {
  margin-top: auto;
}

.browseMoreButton {
  margin-top: 32px;
}

.link {
  text-decoration: none;
}
